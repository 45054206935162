tr:nth-child(even) {
    background-color: #f2f2f2;
}

.Apps {
    text-align: center;
    height: 100vh;
    display: flex;
    background-color: white;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.animation {
    transition: margin-left 0.5s ease;
    /* Add transition for margin-left property */
}

.animation-two {
    transition: width 0.5s ease;
    /* Add transition for margin-left property */
}

.sidebar-exp {
    margin-left: 5%;
}

.sidebar-col {
    margin-left: 20%;
}

.App-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding-bottom: 1vh;
    padding-top: 10%;
}

.App-header2 {
    width: 100%;
    background-color: #212429;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding-bottom: 1vh;
    padding-top: 6%;
}
.text {
    font-size:large;
}
.text2 {
    font-size:xx-large;
}
.text-container {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
@media only screen and (min-width: 768px) {
    .App-header {
      flex-direction: row;
    }

    .App-header > div {
      width: 50%;
    }

    .App-header img {
      width: 100%;
      height: auto;
    }
}
.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


.text-judul {
    font-family: 'Roboto-Bold';
}

.text-des {
    font-family: 'Roboto-Regular';
}

.Box-Content {
    background-color: white;
    width: 70%;
    height: 8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1vw;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-Top: 2%;
}

.content-left {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right: 1%;
}

.content-right {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
    padding-left: 1%;
}

.phone-styles {
    width: 95%;
    background-image: linear-gradient(180deg, rgb(48, 48, 48), rgb(70, 70, 70));
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.content-text-big {
    font-size:7ch;
    color: #212429;
    font-weight: 600;
}

.content-text-small {
    font-size:x-large;
    color: #212429;
    font-weight: 400;
}

.card-content {
    width: 100%;
        height: 70vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #282c34;
}


.card-main {
    width: 100%;
    height: 100%;
}
.card-main2 {
    width: 100%;
    height: 100vh;
}
.card-main-dev{
    width: 30%;
    height: 90%;
    background-color: white;
    margin: 2%;
    border-radius: 10px;
    box-shadow: 10px 10px 5px lightblue;
}


/* Hide vertical scrollbar */
::-webkit-scrollbar {
    width: 0em;
    background: transparent;
    /* Adjust the width as needed */
}

::-webkit-scrollbar-track {
    background: transparent;
    /* Hide the scrollbar track */
}

::-webkit-scrollbar-thumb {
    background-color: transparent;
    /* Hide the scrollbar thumb */
}

/* Hide horizontal scrollbar (if needed) */
::-webkit-scrollbar-horizontal {
    height: 0.5em;
    /* Adjust the height as needed */
}

/* The container must be positioned relative: */
.custom-select {
    position: absolute;
}

.custom-select select {
    display: none;
    /*hide original SELECT element: */
}

.select-selected {
    background-color: DodgerBlue;
}

/* Style the arrow inside the select element: */
.select-selected:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
    color: #ffffff;
    padding: 8px 16px;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
}

/* Style items (options): */
.select-items {
    position: absolute;
    background-color: DodgerBlue;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
    display: none;
}

.select-items div:hover,
.same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}


/* Style The Dropdown Button */
.dropbtn {
    background-color: #F5F9FD;
    color: rgb(56, 56, 56);
    /* width: 10.5vw; */
    height: 5vh;
    font-size: 16px;
    border: 1px solid #c7cbcc;
    cursor: pointer;
    border-radius: 10;
        font-family: 'Roboto-Regular'
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
    /* z-index: 100; */
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #F5F9FD;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgb(56, 56, 56);;
    z-index: 100;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: rgb(56, 56, 56);;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #f1f1f1
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    background-color: white;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;700&display=swap');



.body {
    font-family: 'Poppins', sans-serif;
    align-items: center;
    justify-content: center;
    background-color: #ADE5F9;
    min-height: 100vh;
}

.img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ul {
    list-style: none;
}

/* Utilities */
.card::after,
.card img {
    border-radius: 50%;
}

body,
.card,
.stats {
    display: flex;
}

.card {
    padding: 2.5rem 2rem;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, .5);
    max-width: 500px;
    box-shadow: 0 0 30px rgba(0, 0, 0, .15);
    margin: 1rem;
    position: relative;
    transform-style: preserve-3d;
    overflow: hidden;
}

.card::before,
.card::after {
    content: '';
    position: absolute;
    z-index: -1;
}

.card::before {
    width: 100%;
    height: 100%;
    /* border: 1px solid #FFF; */
    border-radius: 10px;
    top: -.7rem;
    left: -.7rem;
}

.card::after {
    height: 15rem;
    width: 15rem;
    background-color: #fff;
    top: -8rem;
    right: -8rem;
    box-shadow: 2rem 6rem 0 -3rem #fff
}

/* .card img {
    width: 8rem;
    min-width: 80px;
    box-shadow: 0 0 0 5px #FFF;
} */

.infos {
    margin-left: 1.5rem;
}

.name {
    margin-bottom: 1rem;
}

.name h2 {
    font-size: 1.3rem;
}

.name h4 {
    font-size: .8rem;
    color: #333
}

.text {
    font-size: .9rem;
    margin-bottom: 1rem;
}

.stats {
    margin-bottom: 1rem;
}

.stats li {
    min-width: 5rem;
}

.stats li h3 {
    font-size: .99rem;
}

.stats li h4 {
    font-size: .75rem;
}

.links button {
    font-family: 'Poppins', sans-serif;
    min-width: 120px;
    padding: .5rem;
    border: 1px solid #222;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: all .25s linear;
}

.links .follow,
.links .view:hover {
    background-color: #222;
    color: #FFF;
}

@keyframes clickAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.animate-click {
    animation: clickAnimation 0.3s ease;
}

@keyframes colorChange {
    0%, 100% {
        background-image: linear-gradient(180deg, rgba(39, 94, 148, 1), rgb(102, 142, 182));
    }
    50% {
        background-image: linear-gradient(180deg, rgb(153, 187, 221), rgb(212, 221, 240));
    }
}

.color-change {
    animation: colorChange 0.3s ease infinite;
}