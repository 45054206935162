/* App.css */

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;
}

.app-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
}

header {
    text-align: center;
    margin-bottom: 20px;
}

header h1 {
    color: #007bff;
    margin-bottom: 10px;
}

header p {
    color: #555;
}

.product-section {
    margin-bottom: 40px;
}

.product {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    background-color: #fff;
    transition: box-shadow 0.3s ease;
}

.product:hover {
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}

.product h3 {
    color: #007bff;
    margin-bottom: 8px;
}

.product p {
    color: #555;
}

.why-choose-section {
    margin-bottom: 40px;
}

.why-choose-section h2 {
    color: #007bff;
    margin-bottom: 15px;
}

.why-choose-section ul {
    list-style-type: none;
    padding: 0;
}

.why-choose-section li {
    margin-bottom: 10px;
    color: #555;
}

.contact-section {
    margin-bottom: 40px;
}

.contact-section h2 {
    color: #007bff;
    margin-bottom: 15px;
}

.contact-section p {
    margin-bottom: 10px;
    color: #555;
}

.contact-section a {
    color: #007bff;
    text-decoration: none;
}

.contact-section a:hover {
    text-decoration: underline;
    color: #0056b3;
}