.color {
    background: linear-gradient(rgba(250, 0, 0, 0.5), transparent);
    background-color: aqua;
}

.text-judul {
    font-family: 'Roboto-Bold';
}

.text-des {
    font-family: 'Roboto-Regular';
}