.main-wallet{
  padding: 10%;
}

.judul-wallet{
  width: 95vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@keyframes colorChangeWallet {
  0%, 100% {
      opacity: 1;
  }
  50% {
      opacity: 0.5;
  }
}

.color-change-wallet {
  animation: colorChangeWallet 0.3s ease infinite;
}
