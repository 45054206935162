.main-profile {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1024px) {
  /* For mobile and tablet */
  .main-profile {
      padding: 0;
  }
}

.judul-styles {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10%;
  background-color: #6AC673;
}

.main-content-profile-2{
  display:flex;
  align-items:center;
  justify-content:center;
}

.bg-profil{
 background-image: linear-gradient(180deg, rgb(20, 42, 83), rgb(25, 60, 95));
}

.profile-profit {
  width: 30%; /* Default width for mobile and tablet */
  height: 5vh;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {
  /* For desktop */
  .profile-profit {
      width: 7vw;
  }
}

/* styles.css */
.text-profit {
  font-size: 18px; /* Default font size for tablet and mobile */
}

@media (min-width: 1024px) {
  /* For desktop */
  .text-profit {
      font-size: 2vh;
  }
}

.copy-ref {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 75%; /* Default width for mobile and tablet */
  background-color: white;
  height: 44px; /* Default height for tablet and mobile */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

@media (min-width: 1024px) {
  /* For desktop */
  .copy-ref {
      width: 55vw;
      height: 44px;
  }
}

.button-copy-ref {
  background-color: #6AC673;
  flex-direction: row;
  height: 44px; /* Default height for tablet and mobile */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

@media (min-width: 1024px) {
  /* For desktop */
  .button-copy-ref {
      height: 44px;
  }
}

.images-share {
  width: 70px; /* Default width for mobile and tablet */
  height: 30px; /* Default height for mobile and tablet */
  margin-bottom: 5%; /* Default margin-bottom for mobile and tablet */
}

@media (min-width: 1024px) {
  /* For desktop */
  .images-share {
      width: 5vw;
      height: 4vh;
      margin-bottom: 0;
  }
}

.form-profile {
  width: 91vw; /* Default width for tablet and mobile */
  margin: 1%;
  padding: 2%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

@media (min-width: 769px) {
  /* For desktop */
  .form-profile {
      width: 100%; 
  }
}

.verif-profil {
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}


@media (min-width: 769px) {
  /* For desktop */
  .button-profile {
      height: 40px;
  }
}

.button-profile {
  border-radius: 10px;
  width: 100%;
  height: 48px; /* Default height for mobile and tablet */
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

@media (min-width: 1024px) {
  /* For desktop */
  .button-profile {
      height: 6vh;
  }
}

@keyframes colorChangeProfile {
  0%, 100% {
      background-color: #6AC673
  }
  50% {
    background-color: #c2f1c6
  }
}

.color-change-profile {
  animation: colorChangeProfile 0.3s ease infinite;
}