/* Login.css */
.main-css {
    width: 100vw;
    height: 100vh;
    background-color: #212429;
}

.main-box{
    display:flex;
    flex-direction:row;
    width:100%;
}

.left-box {
    display: flex;
    height: 100vh;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    width: 100%;
}

@media (max-width: 1024px) {
    /* For mobile and tablet */
    .left-box {
        width: 100%;
    }
}

.right-box {
    display: flex;
    width: 100%;
background-image: linear-gradient(180deg, rgb(58, 58, 58), rgb(100, 100, 100));
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.left-box-scroll {
    width: 100%;
    height: 100vh;
    padding-top: 2%;
    overflow: scroll;
}

.logo-image {
    margin: auto;
    margin-left: 5%;
    margin-bottom: 2%;
    width: 10vw;
}

@media (max-width: 1024px) {
    /* For mobile and tablet */
    .logo-image {
        width: 120px;
    }
}

.padding-content {
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media (max-width: 1024px) {
    /* For mobile and tablet */
    .padding-content {
        padding-left: 10%;
        padding-right: 10%;
            display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    }
}

.ceklis-box {
    width: 100%;
    height: 100px; /* Default height */
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2%;
    padding-bottom: 1%;
}

@media (max-width: 1024px) {
    /* For mobile and tablet */
    .ceklis-box {
        height: 10vh;
    }
}

/* styles.css */
.text-content {
    display: grid;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.content-style {
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.button-styles-regis {
background-image: linear-gradient(180deg, rgb(28, 101, 40), rgb(81, 192, 116));
    border-radius: 10px;
    width: 100%;
    height: 58px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}

.button-styles-2 {
 background-image: linear-gradient(180deg, rgb(28, 101, 40), rgb(81, 192, 116));
    border-radius: 10px;
    width: 100%;
    height: 58px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}

.phone-styles-regis {
    width: 95%;
    background-color: #344054;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.container {
    max-width: 400px;
    margin: 0 auto;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

label {
    display: block;
    margin-bottom: 8px;
}

input {
    width: 100%;
    padding: 8px;
    margin-bottom: 12px;
}

button {
    background-color: #4caf50;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}

.message {
    margin-top: 16px;
    font-weight: bold;
}

.text-judul {
    font-family: 'Roboto-Bold';
}

.text-des {
    font-family: 'Roboto-Regular';
}

.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    border: none;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@keyframes clickAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.animate-click {
    animation: clickAnimation 0.3s ease;
}

@keyframes colorChange3 {
    0%, 100% {
        background-image: linear-gradient(180deg, rgb(20, 42, 83), rgb(25, 60, 95));
    }
    50% {
        background-image: linear-gradient(180deg, rgb(165, 195, 224), rgb(196, 206, 226));
    }
}

.color-changes-regis {
    animation: colorChange3 0.3s ease infinite;
}

.regis-card-main {
    width: 87%;
}