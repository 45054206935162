/* Login.css */

.main-css {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-style {
    display: flex;
    width: 100%;
}


.login-left-box-style {
    display: flex;
    height: 100vh;
    width: 100%;
}

@media (max-width: 1024px) {
    /* For mobile and tablet */
    .left-box-style {
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}

.logo-image {
    margin: auto;
    margin-left: 5%;
    margin-bottom: 2%;
    width: 5vw;
}

@media (max-width: 1024px) {
    /* For mobile and tablet */
    .logo-image {
        width: 120px;
    }
}

.login-padding-content {
    padding-left: 10%;
    padding-right: 10%;
}

@media (max-width: 1024px) {
    /* For mobile and tablet */
    .login-padding-content {
        padding-left: 10%;
        padding-right: 10%;
    }
}

.background-login {
background-image: linear-gradient(180deg, rgb(28, 101, 40), rgb(81, 192, 116));
    border-radius: 10px;
    width: 100%;
    height: 58px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}

.background-image {
        display: flex;
        width: 50%;
background-image: linear-gradient(180deg, rgb(20, 42, 83), rgb(25, 60, 95));
        justify-content: center;
        align-items: center;
        height: 100vh;
}

.text-button{
        color: rgb(24, 86, 147);
        text-align: center;
        padding-top: 2%;
        cursor: pointer;
}

.text-button-click{
        color: rgb(24, 86, 147);
        text-align: center;
        padding-top: 2%;
        cursor: pointer;
}

.text-button-before{
    color: #344054;
    text-align: center;
    padding-top: 2%;
}

.box-button-text{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-content{
    width: 100%;
    height: 100vh;
    padding-top: 10%;
    overflow: scroll;
}


.container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

label {
    display: block;
    margin-bottom: 8px;
}

input {
    width: 100%;
    padding: 8px;
    margin-bottom: 12px;
}

button {
    background-color: #4caf50;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}

.message {
    margin-top: 16px;
    font-weight: bold;
}

.text-judul {
    font-family: 'Roboto-Bold';
}

.text-des {
    font-family: 'Roboto-Regular';
}


@keyframes colorChangess {
    0%, 100% {
        background-image: linear-gradient(180deg, rgb(20, 42, 83), rgb(25, 60, 95));
    }
    50% {
        background-image: linear-gradient(180deg, rgb(153, 187, 221), rgb(212, 221, 240));
    }
}

.colors-changes-login {
    animation: colorChangess 0.3s ease infinite;
}