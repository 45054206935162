nav {

    padding: 0.5vw 2vw;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position:fixed;
    width: 100vw;
    z-index: 1000;
}

@media (max-width: 1024px) {
    /* For mobile and tablet */
nav {
    padding: 2vw 4vw;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position:fixed;
    width: 100vw;
    z-index: 1000;
}
}



ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

li {
    display: inline-block;
    margin-right: 20px;
}

a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: color 0.3s ease-in-out;
}

a:hover {
    color: #4caf50;
}

.content1 {
    height:80%;
    display: flex;
    align-items: flex-start;
}

.content2 {
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.logo {
    display: flex;
    flex-direction:row;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    /* padding-left: 10%; */
}

text {
    color:white
}

.text-judul {
    font-family: 'Roboto-Bold';
}

.text-des {
    font-family: 'Roboto-Regular';
}

.button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.button-fix{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.image {
    width: 3vh;
    height: 3vh;
}

.text-styling {
    color: white;
    font-size: 1.8vh;
    font-family: 'Roboto-Bold';
}

.text-styling-2 {
    color: white;
    font-size: 1.8vh;
    font-family: 'Roboto-Bold';
}

/* .text-styling:hover {
    color: #3582B8;
    font-size: 1.8vh;
    font-family: 'Roboto-Bold';
} */

.image-home {
width: 3vh;
    height: 3vh;
    background-image: url('../../assets/HomeWhite.png');
    background-size: cover;
    background-position: center;
    transition: background-image 0.3s ease;
}

.image-home:hover {
    background-image: url('../../assets/Home.png');
}