.main-withdraw{
  padding:2%;
  width: 95vw;
}
.judul-withdraw{
  width: 100%;
}

.center-withdraw{
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-content-withdraw{
  margin: 1%;
  padding: 2%;
  border-radius: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}


.rekening-withdraw
{
  height: auto;
}

.button-css-wd {
  border-radius: 10px;
  width: 100%;
  height: 48px;
  cursor: pointer;
}

.button-cover-wd{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.button-drop-wd{
  justify-content: space-between;
  display: flex;
  align-items: center;
  height: 57px;
  background-color: white;
}