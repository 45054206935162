@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.fonts400 {
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
}
.fonts500 {
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
}
.fonts700 {
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
}

.app-title{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
}
.title{
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}
.desc{
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}
.Menu-Item{
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}
.bottomNav{
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
}

#diskon {
    -webkit-text-decoration-line: line-through; /* Safari */
    text-decoration-line: line-through; 
    color: #BFBFBF;
}

.scrollbar::-webkit-scrollbar {
    display: none;
}
.scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
} 

/* Link {
    text-decoration: 'none'
} */