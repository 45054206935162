

.main {
    width: 100vw;
    background-color: #282c34;
    display: flex;
    padding-bottom: 5%;
    padding-top: 2%;
    margin-bottom: 0.1%;
}


.content-footer{
    height: 100%;
    display: flex;
    flex-direction: column;
        background-color: #282c34;
        justify-content: space-around;
        cursor: pointer;
        /* justify-content: space-between; */
}
 .judul{
    font-family:'Roboto-Bold' ;
    color: white;
 }

 .deskripsi{
    font-family: 'Roboto-Regular';
    color: grey;
 }

  .deskripsi:hover {
      font-family: 'Roboto-Regular';
      color: #3582B8;
  }