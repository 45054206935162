.main-css-deposit{
  padding: 2%;
  width: 95vw;
}

.max-wid{
  width: 100%;
}

.main-content-deposit{
  display:flex;
  align-items:center;
}
.main-content-deposit-2{
  display:flex;
  align-items:center;
  justify-content:center;
}

.main-content-deposit-3{
  display:flex;
  align-items:flex-start;
  justify-content:center;
  margin:1%;
  padding:2%;
  border-radius:5;
  flex-direction:column;
}

.button-deposit{
  border-radius:5px;
  width:100%;
  height:48px;
  justify-content:center;
  align-items:center;
  display:flex;
  cursor:pointer
}