

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #212429;
    min-height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding-bottom: 1vh;
    padding-top: 10%;
}

@media only screen and (min-width: 768px) {
    .App-header {
        flex-direction: row;
    }

    .App-header>div {
        width: 50%;
    }

    .App-header img {
        width: 100%;
        height: auto;
    }
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


.text-judul {
    font-family: 'Roboto-Bold';
}

.text-des {
    font-family: 'Roboto-Regular';
}

.Box-Content {
    background-color: white;
    width: 70%;
    height: 8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1vw;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-Top: 2%;
}

.content-left {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right: 1%;
}

.content-right {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 1%;
}

.content-text-big {
    font-size: 7ch;
    color: #212429;
    font-weight: 600;
}

.content-text-small {
    font-size: x-large;
    color: #212429;
    font-weight: 400;
}

.card-content {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #282c34;
}

.card-main {
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-main-dev1 {
    height: 90%;
    background-color: white;
    margin: 2%;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-width: 1vh;
}

.card-main-dev2 {
    height: 50vh;
    background-color: white;
    margin: 2%;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-width: 1vh;
}

.card-main-dev3 {
    height: 30%;
    background-color: white;
    margin: 2%;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-width: 1vh;
}

.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card {
    background-color: #fff;
    padding: 20px;
    width: 300px;
  }
  
  /* Add additional styling as needed for responsiveness */
  
/* Hide vertical scrollbar */
::-webkit-scrollbar {
    width: 0em;
    background: transparent;
    /* Adjust the width as needed */
}

::-webkit-scrollbar-track {
    background: transparent;
    /* Hide the scrollbar track */
}

::-webkit-scrollbar-thumb {
    background-color: transparent;
    /* Hide the scrollbar thumb */
}

/* Hide horizontal scrollbar (if needed) */
::-webkit-scrollbar-horizontal {
    height: 0.5em;
    /* Adjust the height as needed */
}