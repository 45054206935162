/* Login.css */

.container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

label {
    display: block;
    margin-bottom: 8px;
}

input {
    width: 100%;
    padding: 8px;
    margin-bottom: 12px;
}

button {
    background-color: #4caf50;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}

.message {
    margin-top: 16px;
    font-weight: bold;
}

.text-judul {
    font-family: 'Roboto-Bold';
}

.text-des {
    font-family: 'Roboto-Regular';
}


@keyframes clickAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.animate-click {
    animation: clickAnimation 0.3s ease;
}

@keyframes colorChange {
    0%, 100% {
        background-image: linear-gradient(180deg, rgb(51, 80, 160), rgb(68, 114, 200));
    }
    50% {
        background-image: linear-gradient(180deg, rgb(144, 160, 206), rgb(196, 206, 226));
    }
}

.color-change {
    animation: colorChange 0.3s ease infinite;
}

.background-pass{
    background-image: linear-gradient(180deg, rgba(39, 94, 148, 1), rgb(102, 142, 182));
    border-radius: 10px;
    width: 100%;
    height: 58px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}