.App {
    /* text-align: center;
    height: 100vh; */
    background-color: #343a3d;
}
.App-two {
    /* text-align: center;
    height: 100vh; */
    background-color: #343a3d;
    z-index: 10000;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding-bottom: 1vh;
    padding-top: 10%;
}

.App-header2 {
    width: 100%;
    background-color: #212429;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding-bottom: 1vh;
    padding-top: 6%;
}

.text {
    font-size: large;
}

.text2 {
    font-size: xx-large;
}

.text-container {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media only screen and (min-width: 768px) {
    .App-header {
        flex-direction: row;
    }

    .App-header>div {
        width: 50%;
    }

    .App-header img {
        width: 100%;
        height: auto;
    }
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


.text-judul {
    font-family: 'Roboto-Bold';
}

.text-des {
    font-family: 'Roboto-Regular';
}

.Box-Content {
    background-color: white;
    width: 70%;
    height: 8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1vw;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-Top: 2%;
}

.content-left {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right: 1%;
}

.content-right {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 1%;
}

.content-text-big {
    font-size: 7ch;
    color: #212429;
    font-weight: 600;
}

.content-text-small {
    font-size: x-large;
    color: #212429;
    font-weight: 400;
}

.card-content {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #282c34;
}



.card-main {
    width: 100%;
    height: 100%;
    padding: 2%;
}

.card-main2 {
    width: 100%;
    height: 100vh;
    padding: 2%;
}

.card-main-dev {
    width: 30%;
    height: 90%;
    background-color: white;
    margin: 2%;
    border-radius: 10px;
    box-shadow: 10px 10px 5px lightblue;
}


/* Hide vertical scrollbar */
::-webkit-scrollbar {
    width: 0em;
    background: transparent;
    /* Adjust the width as needed */
}

::-webkit-scrollbar-track {
    background: transparent;
    /* Hide the scrollbar track */
}

::-webkit-scrollbar-thumb {
    background-color: transparent;
    /* Hide the scrollbar thumb */
}

/* Hide horizontal scrollbar (if needed) */
::-webkit-scrollbar-horizontal {
    height: 0.5em;
    /* Adjust the height as needed */
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(204, 204, 204, 0.492);
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: #212429;
    -webkit-transition: .4s;
    transition: .4s;
}


.slider-2 {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #212429 ;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider-2:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: rgba(204, 204, 204, 0.492);
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #212429;
}

input:focus+.slider {
    box-shadow: 0 0 1px #212429;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
    background-color: azure;
}

.slider-2.round-2 {
    border-radius: 34px;
}

.slider-2.round-2:before {
    border-radius: 50%;
    background-color: azure;
}

.sidebar-container {
    transition: width 0.5s ease;
    position:fixed;
    z-index:10000;
    height: 120vh;
    /* Add transition for width property */
}

/* Styles when sidebar is expanded */
.sidebar-expanded {
    width: 100%;
}

/* Styles when sidebar is collapsed */
.sidebar-collapsed {
    width: 100%;
}

.app-zero {
    transition: width 0.5s ease;
    /* Add transition for width property */
}

/* Styles when sidebar is expanded */
.app-one {
    width: 6vh;
}

/* Styles when sidebar is collapsed */
.app-two {
    width: 70%;
    position: absolute;
    background-image: linear-gradient(180deg, rgb(51, 80, 160), rgb(68, 114, 200));
        z-index: 100;
}