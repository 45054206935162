@keyframes colorChangeReport {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}

.color-change-report {
    animation: colorChangeReport 0.3s ease infinite;
}