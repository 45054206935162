/* Container for the entire page */
.home-container {
    padding: 6%;
    padding-left: 20%;
    padding-right: 20%;
}

/* Adjustments for mobile view */
@media screen and (max-width: 768px) {
    .home-container {
        padding: 8%;
        padding-left: 10%;
        padding-right: 10%;
    }
}

/* Title styling */
.title h1 {
    font-size: 40px;
}

@media screen and (max-width: 768px) {
    .title h1 {
        font-size: 32px;
    }
}

/* Table styling */
.table-container {
    margin-top: 2%;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .table-container {
        margin-top: 5%;
    }
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

/* Section styling */
.section {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.section h2 {
    font-weight: bold;
    font-size: 24px;
}

@media screen and (max-width: 768px) {
    .section h2 {
        font-size: 20px;
    }
}

.section p {
    font-size: 16px;
    line-height: 1.5;
}

@media screen and (max-width: 768px) {
    .section p {
        font-size: 14px;
    }
}
td,  th {
  border: 1px solid #ddd;
  padding: 8px;
}

tr:nth-child(even){background-color: #f2f2f2;}

tr:hover {background-color: #ddd;}

 th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #ededed;
  color: white;
}
